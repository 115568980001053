export default {
  routes: [
    {
      path: "budget-timeframe",
      name: "budget-timeframe",
      component: () => import("./BudgetTimeframe.vue"),
      meta: {
        title: "Budget Time Frame",
        roles: [""],
        permission: ["can_update_budget_settings"],
      },
    },
    {
      path: "budget-advanced",
      name: "budget-advanced",
      component: () => import("./BudgetAdvanced.vue"),
      meta: {
        title: "Budget Advanced",
        roles: [""],
        permission: ["can_update_budget_settings"],
      },
    },
    {
      path: "budget-performance",
      name: "budget-performance",
      component: () => import("./BudgetPerformance.vue"),
      meta: {
        title: "Budget Performance",
        roles: [""],
        permission: ["can_access_budgeting_performance"],
      },
    },
  ],
};
