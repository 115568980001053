export default {
  routes: [
    {
      path: ":id",
      name: "show-teams",
      component: () => import("./WrapperShowTeams.vue"),
      meta: {
        title: "Teams",
        roles: [""],
        permissions: ["can_view_inhouse_teams"],
      },
      redirect: { name: "show-teams-details" },
      children: [
        {
          path: "details",
          name: "show-teams-details",
          component: () => import("./ShowTeamsDetails.vue"),
          meta: {
            title: "Teams",
            permissions: ["can_view_inhouse_teams"],
            roles: [""],
          },
        },
        {
          path: "members",
          name: "show-teams-members",
          component: () => import("./ShowTeamsMembers.vue"),
          meta: {
            title: "Teams",
            permissions: ["can_view_inhouse_teams"],
            roles: [""],
          },
        },
        {
          path: "teams-logs",
          name: "teams-logs",
          component: () => import("@/components/ui/ActivityLog.vue"),
          meta: {
            title: "Teams",
            permissions: ["can_view_inhouse_teams"],
            roles: [],
          },
        },
      ],
    },
  ],
};
