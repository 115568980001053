export default {
  routes: [
    {
      path: "operation-expenses",
      name: "operation-expenses",
      component: () => import("./OperationExpenses.vue"),
      meta: {
        title: "Operation Expenses",
        roles: [""],
      },
    },
    {
      path: "asset-expenses",
      name: "asset-expenses",
      component: () => import("./AssetExpenses.vue"),
      meta: {
        title: "Asset Expenses",
        roles: [""],
      },
    },
    {
      path: "parts-expenses",
      name: "parts-expenses",
      component: () => import("./PartsExpenses.vue"),
      meta: {
        title: "Parts Expenses",
        roles: [""],
      },
    },
    {
      path: "inventory-expenses",
      name: "inventory-expenses",
      component: () => import("./InventoryExpenses.vue"),
      meta: {
        title: "Inventory Expenses",
        roles: [""],
      },
    },
  ],
};
