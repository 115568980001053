import showPropertyRoute from "./ShowProperty/show.property.route";

export default {
  routes: [
    {
      path: "/properties",
      component: () => import("./PropertiesIndex.vue"),
      redirect: { name: "properties" },
      meta: {
        title: "properties.title",
        roles: [""],
        permissions: ["can_access_properties", "can_list_properties"],
      },
      children: [
        {
          path: "",
          name: "properties",
          component: () => import("./WrapperProperties.vue"),
          meta: {
            title: "properties.title",
            roles: [""],
            permissions: ["can_access_properties", "can_list_properties"],
          },
        },
        {
          path: "create",
          name: "create-property",
          component: () => import("./CreateProperty/CreateProperty.vue"),
          meta: {
            title: "properties.form.create_new_property",
            roles: [""],
            isEdit: false,
            permissions: ["can_create_properties"],
          },
        },
        {
          path: "edit/:id",
          name: "edit-property",
          component: () => import("./CreateProperty/CreateProperty.vue"),
          meta: {
            title: "properties.form.edit_property",
            roles: [""],
            isEdit: true,
            permissions: ["can_update_properties"],
          },
        },
        {
          path: "",
          name: "show-property-index",
          component: () => import("./ShowProperty/IndexShowProperty.vue"),
          redirect: { name: "show-property" },
          children: [...showPropertyRoute.routes],
        },
      ],
    },
  ],
};
