// http client helper
import axios from "axios";
import { useToastService } from "./toast.helper";
import { extractKeyword } from "./url.helper";
import router from "../router";
import i18n from "../plugins/I18n";

const { showToast } = useToastService();

const URL = import.meta.env.VITE_BASE_API_URL;
const axiosClient = axios.create({
  baseURL: URL,
  headers: {
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
  },
});

axiosClient.interceptors.request.use((config) => {
  const token = window.$cookies.get(
    `${extractKeyword(window.location.href)}_mc_token`,
  );

  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

// if 401 remove cookie and redirect to login
axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // const { errors } = error.response.data;
    // if (errors instanceof Object && Object.keys(errors).length ) {
    //   for (const item in errors) {
    //     showToast("Error Message", errors[item], "error");
    //   }
    // } else {

    let errorMessage = error.response.data.message || error.response.data.data.message

    showToast(
      i18n.global.t("global.massages.error"),
      errorMessage,
      "error",
    );
    // }
    if (error.response.status == 401) {
      window.$cookies.remove(`${extractKeyword(window.location.href)}_mc_user`);
      window.$cookies.remove(
        `${extractKeyword(window.location.href)}_mc_companies`,
      );
      window.$cookies.remove(
        `${extractKeyword(window.location.href)}_mc_token`,
      );
      router.push({ name: "login" });
    }

    return Promise.reject(error);
  },
);

export default axiosClient;
