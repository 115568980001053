import showTeamsRoute from "./show.teams.route";

export default {
  routes: [
    {
      path: "/teams",
      name: "teams-index",
      component: () => import("./TeamsIndex.vue"),
      meta: {
        title: "Teams",
        roles: [""],
      },
      children: [
        {
          path: "",
          name: "teams",
          component: () => import("./WrapperTeams.vue"),
          redirect: { name: "teams-list-view" },
          children: [
            {
              path: "calendar",
              name: "teams-calendar-view",
              component: () =>
                import(
                  "@/views/Dashboard/WorkOrders/CorrectiveOrders/WrapperCorrective.vue"
                ),
              meta: {
                title: "Teams",
                roles: [""],
                permissions: [
                  "can_access_inhouse_teams",
                  "can_list_inhouse_teams",
                ],
              },
            },
            {
              path: "list",
              name: "teams-list-view",
              component: () => import("./TeamsList.vue"),
              meta: {
                title: "Teams",
                roles: [""],
                permissions: [
                  "can_access_inhouse_teams",
                  "can_list_inhouse_teams",
                ],
              },
            },
            {
              path: "cards",
              name: "teams-cards-view",
              component: () => import("./TeamsListCards.vue"),
              meta: {
                title: "Teams",
                roles: [""],
                permissions: [
                  "can_access_inhouse_teams",
                  "can_list_inhouse_teams",
                ],
              },
            },
          ],
        },
        {
          path: "create",
          name: "create-teams",
          component: () => import("./CreateTeam.vue"),
          meta: {
            permissions: ["can_create_inhouse_teams"],
          },
        },
        {
          path: "edit/:id",
          name: "edit-teams",
          component: () => import("./CreateTeam.vue"),
          meta: {
            isEdit: true,
            permissions: ["can_update_inhouse_teams"],
          },
        },
        {
          path: "review/:id",
          name: "review-teams",
          component: () => import("./BeforeCreateMember.vue"),
          meta: {
            permissions: ["can_update_inhouse_teams"],
          },
        },
        {
          path: "create-members/:id",
          name: "create-teams-members",
          component: () => import("./CreateTeamMembers.vue"),
          meta: {
            permissions: ["can_update_inhouse_teams"],
          },
        },
        {
          path: "edit-members/:id",
          name: "edit-teams-members",
          component: () => import("./CreateTeamMembers.vue"),
          meta: {
            isEdit: true,
            permissions: ["can_update_inhouse_teams"],
          },
        },
        {
          path: "",
          name: "show-teams-index",
          component: () => import("./IndexShowTeams.vue"),
          redirect: { name: "show-teams" },
          children: [...showTeamsRoute.routes],
          meta: {
            permissions: ["can_view_inhouse_teams"],
          },
        },
      ],
    },
  ],
};
