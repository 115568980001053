export default {
  routes: [
    {
      path: "",
      component: () => import("./LoginWrapper.vue"),
      redirect: { name: "login" },
      children: [
        {
          path: "login",
          name: "login",
          component: () => import("./LoginForm.vue"),
        },
        {
          path: "verification",
          name: "verification",
          component: () => import("./VerificationForm.vue"),
        },
        {
          path: "register/complete/:code",
          name: "register-complete",
          component: () => import("./RegisterComplete.vue"),
        },
      ],
    },
    {
      path: "/forget-password",
      name: "forget-pass",
      component: () => import("./ForgetPass.vue"),
    },
  ],
};
