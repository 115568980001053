export default {
  routes: [
    {
      path: "/companies",
      component: () => import("./WrapperCompanies.vue"),
      redirect: { name: "admin-companies-list" },
      meta: {
        title: "companies",
        roles: [""],
      },
      children: [
        {
          path: "",
          name: "admin-companies-list",
          component: () => import("./CompaniesList.vue"),
          meta: {
            title: "Companies",
            roles: [""],
          },
        },
        {
          path: "create",
          name: "admin-create-company",
          component: () => import("./AdminCreateCompany.vue"),
          meta: {
            title: "create company",
            roles: [""],
          },
        },
        {
          path: ":id/edit",
          name: "admin-edit-company",
          component: () => import("./AdminCreateCompany.vue"),
          meta: {
            title: "edit company",
            roles: [""],
          },
        },
      ],
    },
  ],
};
