export default {
  routes: [
    {
      path: "/unit-residents",
      name: "unit-residents",
      redirect: "/",
      meta: {
        title: "Unit Occupant",
        roles: [""],
      },
      children: [
        {
          path: "",
          name: "unit-residents-list",
          component: () => import("./UnitResidentsList.vue"),
          meta: {
            roles: [""],
            permissions: [
              "can_access_unit_residents",
              "can_list_unit_residents",
            ],
          },
        },
        {
          path: "create",
          name: "create-unit-resident",
          component: () => import("./NewUnitResident.vue"),
          meta: {
            roles: [""],
            permissions: ["can_create_unit_residents"],
          },
        },
        {
          path: ":id/edit",
          name: "edit-unit-resident",
          props: true,
          component: () => import("./NewUnitResident.vue"),
          meta: {
            roles: [""],
            permissions: ["can_update_unit_residents"],
            isEdit: true,
          },
        },
        {
          path: ":id",
          name: "unit-resident-details",
          props: true,
          component: () => import("./details/ResidentDetailsWrapper.vue"),
          meta: {
            roles: [""],
            permissions: ["can_view_unit_residents"],
          },
          redirect: { name: "unit-resident-info" },
          children: [
            {
              path: "details",
              name: "unit-resident-info",
              props: true,
              component: () => import("./details/ResidentInfo.vue"),
              meta: {
                permissions: ["can_view_unit_residents"],
              },
            },
            {
              path: "payments",
              name: "unit-resident-payments",
              props: true,
              component: () => import("./details/ResidentPayments.vue"),
              meta: {
                permissions: ["can_view_unit_residents"],
              },
            },
            {
              path: "unit-resident-logs",
              name: "unit-resident-logs",
              component: () => import("@/components/ui/ActivityLog.vue"),
              meta: {
                roles: [""],
                permissions: ["can_view_unit_residents"],
              },
            },
          ],
        },
      ],
    },
  ],
};
