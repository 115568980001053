export default {
  routes: [
    {
      path: ":id",
      name: "show-site",
      component: () => import("./WrapperShowSite.vue"),
      meta: {
        title: "sites.form.add.title",
        roles: [""],
        permissions: ["can_view_sites"],
      },
      redirect: { name: "show-site-details" },
      children: [
        {
          path: "details",
          name: "show-site-details",
          component: () => import("./ShowSiteDetails.vue"),
          meta: {
            title: "sites.form.add.title",
            roles: [""],
            permissions: ["can_view_sites"],
          },
        },
        {
          path: "customers",
          name: "show-site-customers",
          component: () => import("./ShowSiteCustomers.vue"),
          meta: {
            title: "sites.form.add.title",
            roles: [""],
            permissions: ["can_view_sites"],
          },
        },
        {
          path: "properties",
          name: "show-site-properties",
          component: () => import("./ShowSiteProperties.vue"),
          meta: {
            title: "sites.form.add.title",
            roles: [""],
            permissions: ["can_view_sites"],
          },
        },
        {
          path: "work-order",
          name: "show-site-work-order",
          component: () => import("./ShowSiteWorkOrder.vue"),
          meta: {
            title: "sites.form.add.title",
            roles: [""],
            permissions: ["can_view_sites"],
          },
        },
        {
          path: "site-logs",
          name: "site-logs",
          component: () => import("@/components/ui/ActivityLog.vue"),
          meta: {
            title: "sites.form.add.title",
            roles: [""],
            permissions: ["can_view_sites"],
          },
        },
      ],
    },
  ],
};
