export default {
  routes: [
    {
      path: "/subscription",
      name: "subscription",
      component: () => import("./SubscriptionWrapper.vue"),
      redirect: { name: "subscription_list" },
      meta: {
        title: "Subscription plan",
        roles: [""],
        showBackButton: false,
      },
      children: [
        {
          path: "",
          name: "subscription_list",
          component: () => import("./SubscriptionList.vue"),
        },
        {
          path: "proceed/:id",
          name: "subscription_proceed",
          component: () => import("./SubscriptionProceed.vue"),
        },
        {
          path: "checkout",
          name: "subscription_checkout",
          component: () => import("./SubscriptionCheckout.vue"),
        },
      ],
    },
  ],
};
