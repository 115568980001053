export default {
  routes: [
    {
      path: "/parts-inventory",
      name: "parts-inventory-index",
      component: () => import("./PartsInventoryIndex.vue"),
      meta: {
        title: "Parts & Inventory",
        roles: [],
        permissions: ["can_access_part_inventory", "can_list_part_inventory"],
      },
      children: [
        {
          path: "",
          name: "parts-inventory",
          component: () => import("./WrapperPartsInventory.vue"),
          meta: {
            title: "Parts & Inventory",
            roles: [],
            permissions: [
              "can_access_part_inventory",
              "can_list_part_inventory",
            ],
          },
        },
        {
          path: "create",
          name: "create-part",
          component: () => import("./CreatePart.vue"),
          meta: {
            roles: [],
            permissions: ["can_create_part_inventory"],
          },
        },
        {
          path: "edit/:id",
          name: "edit-part",
          component: () => import("./CreatePart.vue"),
          meta: {
            isEdit: true,
            permissions: ["can_update_part_inventory"],
            roles: [],
          },
        },
        {
          path: ":id",
          name: "parts-details",
          component: () => import("./PartDetailsWrapper.vue"),
          meta: {
            title: "details",
            roles: [""],
            permissions: ["can_view_part_inventory"],
          },
          redirect: { name: "details" },
          children: [
            {
              path: "details",
              name: "details",
              component: () => import("./PartDetails.vue"),
              meta: {
                title: "Parts & Inventory",
                permissions: ["can_view_part_inventory"],
                roles: [],
              },
            },
            {
              path: "inventory",
              name: "inventory",
              component: () => import("./PartInventoryLine.vue"),
              meta: {
                title: "Parts & Inventory",
                permissions: ["can_view_part_inventory"],
                roles: [],
              },
            },
            {
              path: "activity",
              name: "activity",
              component: () => import("./PartActivity.vue"),
              meta: {
                title: "Parts & Inventory",
                permissions: ["can_view_part_inventory"],
                roles: [],
              },
            },
            {
              path: "orders/corrective",
              name: "orders-corrective",
              component: () =>
                import("./PartsInventoryBoard//CorrectiveBoard.vue"),
              meta: {
                title: "Parts & Inventory",
                permissions: ["can_view_part_inventory"],
                roles: [],
              },
            },
            {
              path: "orders/ppm",
              name: "orders-ppm",
              component: () => import("./PartsInventoryBoard//PPMBoard.vue"),
              meta: {
                title: "Parts & Inventory",
                permissions: ["can_view_part_inventory"],
                roles: [],
              },
            },
            {
              path: "parts-logs",
              name: "parts-logs",
              component: () => import("@/components/ui/ActivityLog.vue"),
              meta: {
                title: "Parts & Inventory",
                permissions: ["can_view_part_inventory"],
                roles: [],
              },
            },
          ],
        },
      ],
    },
  ],
};
