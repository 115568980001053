export default {
  routes: [
    {
      path: ":id",
      name: "show-category",
      component: () => import("./WrapperShowCategory.vue"),
      meta: {
        title: "Library",
        roles: [""],
        permissions: ["can_view_category"],
      },
      redirect: { name: "show-category-details" },
      children: [
        {
          path: "details",
          name: "show-category-details",
          component: () => import("./CategoryDetails.vue"),
          meta: {
            title: "Library",
            roles: [""],
            permissions: ["can_view_category"],
          },
        },
        {
          path: "work-orders/corrective",
          name: "work-orders-corrective",
          component: () => import("./CategoryBoard/CorrectiveBoard.vue"),
          meta: {
            title: "Library",
            permissions: ["can_view_category"],
            roles: [],
          },
        },
        {
          path: "work-orders/ppm",
          name: "work-orders-ppm",
          component: () => import("./CategoryBoard/PPMBoard.vue"),
          meta: {
            title: "Library",
            permissions: ["can_view_category"],
            roles: [],
          },
        },
        {
          path: "categories-logs",
          name: "categories-logs",
          component: () => import("@/components/ui/ActivityLog.vue"),
          meta: {
            title: "Library",
            permissions: ["can_view_category"],
            roles: [],
          },
        },
      ],
    },
  ],
};
