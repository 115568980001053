import showProcedureRoute from "./show.procedure.route";

export default {
  routes: [
    {
      path: "/procedures",
      name: "procedures-index",
      component: () => import("./WrapperProcedures.vue"),
      meta: {
        title: "Library",
        roles: [""],
        permissions: ["can_list_procedures", "can_access_procedures"],
      },
      children: [
        {
          path: "",
          name: "procedures",
          component: () => import("./ProcedureList.vue"),
          meta: {
            permissions: ["can_list_procedures", "can_access_procedures"],
          },
        },
        {
          path: "create/:id",
          name: "create-procedure",
          component: () => import("./CreateProcedure.vue"),
          meta: {
            roles: [""],
            meta: {
              permissions: ["can_create_procedures"],
            },
          },
        },
        {
          path: "edit/:id",
          name: "edit-procedure",
          component: () => import("./CreateProcedure.vue"),
          meta: {
            roles: [""],
            isEdit: true,
            permissions: ["can_update_procedures"],
          },
        },
        {
          path: "assign/:id",
          name: "assign-procedure",
          component: () => import("./AssignProcedure.vue"),
          meta: {
            roles: [""],
          },
        },
        {
          path: "assign/:id/edit",
          name: "edit-assign-procedure",
          component: () => import("./AssignProcedure.vue"),
          meta: {
            roles: [""],
            isEdit: true,
          },
        },
        {
          path: "",
          name: "show-procedure",
          component: () => import("./IndexShowProcedure.vue"),
          redirect: { name: "show-procedure" },
          children: [...showProcedureRoute.routes],
        },
      ],
    },
  ],
};
