import showUnitRoute from "./ShowUnit/show.unit.route";

export default {
  routes: [
    {
      path: "/units",
      component: () => import("./UnitsIndex.vue"),
      redirect: { name: "units" },
      meta: {
        title: "units.title",
        permissions: ["can_access_units", "can_list_units"],
        roles: [""],
      },
      children: [
        {
          path: "",
          name: "units",
          component: () => import("./WrapperUnits.vue"),
          meta: {
            title: "units.title",
            permissions: ["can_access_units", "can_list_units"],
            roles: [""],
          },
        },
        {
          path: "create",
          name: "create-unit",
          component: () => import("./CreateUnits/CreateUnits.vue"),
          meta: {
            title: "units.form.create_unit",
            roles: [""],
            permissions: ["can_create_units"],

            isEdit: false,
          },
        },
        {
          path: "edit/:id",
          name: "edit-unit",
          component: () => import("./CreateUnits/CreateUnits.vue"),
          meta: {
            title: "units.form.edit_unit",
            permissions: ["can_update_units"],
            roles: [""],
            isEdit: true,
          },
        },
        {
          path: "",
          name: "show-unit-index",
          component: () => import("./ShowUnit/IndexShowUnit.vue"),
          redirect: { name: "show-unit" },
          children: [...showUnitRoute.routes],
        },
      ],
    },
  ],
};
