import showPPMVendorRoute from "./ShowPPM/show.ppm.vendor.route";

export default {
  routes: [
    {
      path: "order-ppm",
      name: "vendor-ppm-index",
      component: () => import("./PPMIndex"),
      redirect: { name: "vendor-ppm" },
      meta: {
        title: "PPM",
        roles: [""],
      },
      children: [
        {
          path: "",
          name: "vendor-ppm",
          component: () => import("./WrapperPPM.vue"),
          meta: {
            title: "PPM",
            roles: [""],
          },
          redirect: { name: "vendor-ppm-list-view" },
          children: [
            {
              path: "list",
              name: "vendor-ppm-list-view",
              component: () => import("./ListView/PPMList.vue"),
              meta: {
                title: "list",
                roles: [""],
              },
            },
          ],
        },
        {
          path: ":id",
          name: "vendor-show-ppm",
          component: () => import("./ShowPPM/WrapperShowPPM.vue"),
          props: true,
          meta: {
            title: "detail",
            roles: [""],
          },
          redirect: { name: "vendor-ppm-details" },
          children: [...showPPMVendorRoute.routes],
        },
      ],
    },
  ],
};
