export default {
  routes: [
    {
      path: "/property-owners",
      name: "property-owners",
      redirect: "/",
      meta: {
        title: "Property Owners",
        roles: [""],
        permissions: ["can_access_property_owners", "can_list_property_owners"],
      },
      children: [
        {
          path: "",
          name: "property-owners-list",
          component: () => import("./PropertyOwnersList.vue"),
          meta: {
            title: "Property Owners",
            roles: [""],
            permissions: [
              "can_access_property_owners",
              "can_list_property_owners",
            ],
          },
        },
        {
          path: "create",
          name: "create-property-owners",
          component: () => import("./NewPropertyOwner.vue"),
          meta: {
            title: "Property Owners",
            roles: [""],
            permissions: ["can_create_property_owners"],
          },
        },
        {
          path: ":id/edit",
          name: "edit-property-owners",
          props: true,
          component: () => import("./NewPropertyOwner.vue"),
          meta: {
            roles: [""],
            permissions: ["can_update_unit_residents"],
            isEdit: true,
          },
        },
        {
          path: ":id",
          name: "property-owners-info",
          props: true,
          meta: {
            navigationPath: ["Property Owners", "Hameed Quadri"],
            permissions: ["can_view_property_owners"],
          },
          component: () => import("./WrapperPropertyOwner.vue"),
          redirect: { name: "property-owners-details" },
          children: [
            {
              path: "details",
              name: "property-owners-details",
              props: true,
              component: () => import("./PropertyOwnerDetails.vue"),
              meta: {
                permissions: ["can_view_unit_residents"],
              },
            },
            {
              path: "property-owners-logs",
              name: "property-owners-logs",
              component: () => import("@/components/ui/ActivityLog.vue"),
              meta: {
                roles: [""],
                permissions: ["can_view_property_owners"],
              },
            },
          ],
        },
      ],
    },
  ],
};
