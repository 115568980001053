import showVendorsRoute from "./show.vendors.route";

export default {
  routes: [
    {
      path: "/vendors",
      name: "vendors-index",
      component: () => import("./VendorsIndex.vue"),
      meta: {
        title: "Vendors & Teams",
        roles: [""],
        permissions: ["can_access_vendors", "can_list_vendors"],
      },
      children: [
        {
          path: "",
          name: "vendors",
          component: () => import("./WrapperVendors.vue"),
          meta: {
            title: "Vendors & Teams",
            roles: [""],
            permissions: ["can_access_vendors", "can_list_vendors"],
          },
        },
        {
          path: "create",
          name: "create-vendor",
          component: () => import("./CreateVendor.vue"),
          meta: {
            title: "create Vendors",
            roles: [""],
            permissions: ["can_create_vendors"],
          },
        },
        {
          path: "edit/:id",
          name: "edit-vendor",
          component: () => import("./CreateVendor.vue"),
          meta: {
            isEdit: true,
            title: "Edit Vendors",
            roles: [""],
            permissions: ["can_update_vendors"],
          },
        },
        {
          path: "",
          name: "show-vendors-index",
          component: () => import("./IndexShowVendor.vue"),
          redirect: { name: "show-vendors" },
          children: [...showVendorsRoute.routes],
          meta: {
            roles: [""],
            permissions: ["can_view_vendors"],
          },
        },
      ],
    },
  ],
};
