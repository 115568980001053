export default {
  routes: [
    {
      path: "/locations",
      name: "locations",
      component: () => import("./WrapperAllLocations.vue"),
      meta: {
        title: "all.title",
        roles: [""],
        permissions: [
          "can_access_all_locations",
          // "can_activate_or_deactivate_locations",
        ],
      },
    },
  ],
};
